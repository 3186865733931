import request from "../service/request"
let url = process.env.VUE_APP_BASE_URL // 正式接口地址
let testUrl = process.env.VUE_APP_BASE_URL_TEST //测试接口地址
function dynamicUrl(url) {
  if (url) {
    let exportUrl = '';
    Object.keys(url).map((key) => {
      exportUrl += key + '=' + url[key] + '&';
    })
    exportUrl = exportUrl.substring(exportUrl.length - 1, 0)
    return exportUrl;
  }
}
// 获取公司主体下拉
export const GetCompanyentity= ()=> request.get(testUrl+'/contractLock/companyList')
// 获取制式下拉选中的(查单个的)
export const GetContractPreviewData= (para)=> request.get(testUrl+'/contractLock/getContractPreviewData?'+dynamicUrl(para))
// 获取制式合同pdf
export const GetStandard= (para)=> request.post(testUrl+'/contractLock/templatePage',para)
// 获取制式合同参数
export const GetTemplateParametersList= (para)=> request.post(testUrl+'/contractLock/templateParametersList',para)

// 获取合同模板下拉
export const GetConttemplate= (para)=> request.post(testUrl+'/contractLock/categoryList',para)

// 保存制式合同
export const CreateStandardContract= (para)=> request.post(testUrl+'/contractLock/createStandardContract',para)

// 制式合同列表
export const GetContractInfo= (para)=> request.get(testUrl+'/contractLock/getContractInfoList?'+dynamicUrl(para))
// 删除合同
export const RemoveContract= (para)=> request.post(testUrl+'/contractLock/removeContract',para)
// 制式合同检查身份
export const CheckPerson= (para)=> request.get(testUrl+'/contractLock/qxAuth2check?'+dynamicUrl(para))
// 签约项目接口+报价类型下拉数据
export const GetCategoryTagList= ()=> request.get(testUrl+'/contractLock/categoryTagList')
// 特批类型下拉数据
export const GetSpecialTagList= (para)=> request.get(testUrl+'/contractLock/getSpecialTagList?'+dynamicUrl(para))
// 服务费收费标准下拉数据
export const GetServiceFeeList= (para)=> request.get(testUrl+'/contractLock/getServiceFeeList?'+dynamicUrl(para))
// 获取最后保存成功的合同信息中的公司名称和签约项目
export const GetNewContractData= (para)=> request.get(testUrl+'/contractLock/getNewContractData?'+dynamicUrl(para))
// 下载预览的合同
export const DownContractUrl = (para) =>
  request.get(testUrl + '/contractLock/downContractUrl?' + dynamicUrl(para), {
    responseType: 'blob'
  });
// 生成契约锁二维码
export const GetContractUrl = (para) =>
  request.get(testUrl + '/contractLock/getContractUrl?' + dynamicUrl(para));
// 获取协议内容数据
export const GetSupAgreementDropDown=(para)=> request.get(testUrl+'/contractLock/getSupAgreementDropDown?' + dynamicUrl(para))
// 校验协议内容数据
export const CheckSupAgreementByIds= (para)=> request.get(testUrl+'/contractLock/checkSupAgreementByIds?' + dynamicUrl(para))
/* E 制式 2024-05-14*/

/* S 特批名额条款配置 2025-03-26*/
// 获取特批名额分类下拉数据
export const GetSpecialTagDropDown= ()=> request.get(testUrl+'/contractLock/getSpecialTagDropDown')
// 特批名额条款列表
export const getSupAgreementList= (para)=> request.get(testUrl+'/contractLock/getSupAgreementList?' + dynamicUrl(para))
// 添加特批名额条款
export const AddSupAgreement= (para)=> request.post(testUrl+'/contractLock/addSupAgreement',para)
// 修改特批名额条款
  export const EditSupAgreement= (para)=> request.post(testUrl+'/contractLock/editSupAgreement',para)
// 停用/启用特批名额条款
export const EditSupAgreementMainData= (para)=> request.post(testUrl+'/contractLock/editSupAgreementMainData',para)
// 删除特批名额条款
export const RemoveSupAgreement= (para)=> request.post(testUrl+'/contractLock/removeSupAgreement',para)
// 查看特批名额条款详情
//
export const getSupAgreement= (para)=> request.get(testUrl+'/contractLock/getSupAgreement?' + dynamicUrl(para))
/* E 特批名额条款配置 2025-03-26*/


/* S 非制式 2024-05-15*/
// 获取非制式下拉选中的+签署位置 /contractLock/getNonContractPreviewData
export const GetNonContractPreviewData= (para)=> request.get(testUrl+'/contractLock/getNonContractPreviewData?'+dynamicUrl(para))
//关键字索引下拉
export const GetKeyWordIndexList= ()=> request.get(testUrl+'/contractLock/getKeyWordIndexList')
// 签署类型下拉
export const GetSignatureTypeList= ()=> request.get(testUrl+'/contractLock/getSignatureTypeList')
// 保存非制式合同
export const CreateNonStandardContract= (para)=> request.post(testUrl+'/contractLock/createNonStandardContract',para)
/* E 非制式 2024-05-15*/